<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="12">
                <v-card :elevation="1">
                    <v-card-text class="py-1">
                        <v-row dense>
                            <v-col cols="12" md="3" v-if="user.role == 'User' || user.role == 'Entry User' ? false : true">
                                <v-combobox label="Department" dense outlined hide-details
                                    :items="$store.getters['department/departments']" v-model="department"
                                    item-text="name" item-value="id"
                                    :loading="$store.getters['department/loadingDepartments']"
                                    @focus="$store.dispatch('department/getDepartments')"
                                    class="custom-form"></v-combobox>
                            </v-col>

                            <v-col cols="12" md="3">
                                <v-combobox label="Employee" dense outlined hide-details v-model="employee"
                                    :items="$store.getters['employee/employees']"
                                    :loading="$store.getters['employee/loadingEmployees']" item-text="display_name"
                                    item-value="id" class="mb-1 custom-form" @focus="getEmployees"></v-combobox>
                            </v-col>

                            <v-col cols="12" md="2">
                                <v-menu :close-on-content-click="false" transition="scale-transition" offset-y
                                    min-width="auto" v-model="menu">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field dense outlined hide-details v-model="date_from" label="Date From"
                                            v-on="on" class="custom-form"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date_from" @input="menu = false"></v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" md="2">
                                <v-menu :close-on-content-click="false" transition="scale-transition" offset-y
                                    min-width="auto" v-model="menu2">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field dense outlined hide-details v-model="date_to" label="Date To"
                                            v-on="on" class="custom-form"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date_to" @input="menu2 = false"></v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" md="2">
                                <v-btn elevation="1" small color="primary" dark style="margin-top:-2px"
                                    @click="getPresentList"> Search </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-toolbar dense color="white" :elevation="1" height="30px">
                    <v-toolbar-title class="subtitle-2">Present History</v-toolbar-title>
                </v-toolbar>
            </v-col>
        </v-row>
        <v-row v-if="present_list.length > 0">
            <v-col cols="12">
                <v-btn color="primary" class="white--text" small dense @click="print">
                    <v-icon left small dense> mdi-printer </v-icon>
                    Print
                </v-btn>
            </v-col>
            <v-col cols="12" md="12" class="py-1" id="reportContent">
                <v-simple-table dense class="record_table">
                    <tr class="record_heading_tr">
                        <th>SL.</th>
                        <th>Date</th>
                        <th>Employee Id</th>
                        <th>Employee Name</th>
                        <th>Designation</th>
                    </tr>
                    <tr v-for="(data, sl) in present_list" :key="sl">
                        <td>{{+ + sl }}</td>
                        <td>{{ data.date }}</td>
                        <td>{{ data.emp_id }}</td>
                        <td>{{ data.employee_name }}</td>
                        <td>{{ data.designation_name }}</td>
                    </tr>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import utility from '../../mixins/utility.mixin';
export default {
    mixins: [utility],
    data() {
        return {
            date_from: this.toISOLocal().substr(0, 10),
            date_to: this.toISOLocal().substr(0, 10),
            department: null,
            employee: null,
            present_list: [],
            user: {},
            menu: false,
            menu2: false,
        }
    },
    async created() {
        this.user = this.$store.getters['user/getUser'];
        if (this.user.role == 'User' || this.user.role == 'Entry User') {
            await this.$store.dispatch('department/getDepartments');
            this.department = this.$store.getters['department/departments'].find(item => item.id == this.user.department_id);
        }
        this.getPresentList();
    },

    methods: {
        getEmployees() {
            this.$store.dispatch('employee/getEmployees', {
                apiParams: {
                    departmentId: this.department?.id ?? null
                },
            })
        },


        async getPresentList() {
            this.present_list = await this.$store.dispatch('attendance/getAttendanceList', {
                apiParams: {
                    date_from: this.date_from,
                    date_to: this.date_to,
                    departmentId: this.department?.id ?? null,
                    employeeId: this.employee?.id ?? null,
                    status: ['P', 'L'],
                },
                options: {
                    returnData: true,
                }
            });
        },
        async print() {
            let title = 'Present History (' + this.date_from + ' to ' + this.date_to + ')';

            let company_profile = this.$store.getters['company/company'];

            // Get HTML to print from element
            const prtHtml = document.getElementById('reportContent').innerHTML;

            // Get all stylesheets HTML
            let stylesHtml = '';
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                stylesHtml += node.outerHTML;
            }

            // Open the print window
            // const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
            const WinPrint = window.open('', 'PRINT', `height=${screen.height}, width=${screen.width}`);
            WinPrint.document.write(`<!DOCTYPE html>
            <html>
            <head>
                ${stylesHtml}
            </head>
            <body>
                <div data-app="true" class="v-application v-application--is-ltr theme--light" id="app">
                    <div class="container container--fluid">
                        <div class="row row--dense">
                            <div class="col-2 px-2">
                                <img style="width: 100%; height: 50px;" src="${this.$store.state.host + company_profile.logo}">
                            </div>
                            <div class="col-10 px-2 text-center">
                                <span class="font-weight-black">
                                    ${company_profile.name}
                                </span>
                                <br>
                                <span>${company_profile.address}</span>
                                <br>
                                <span>
                                    Email:${company_profile.email}, Contact:${company_profile.phone}
                                </span>
                            </div>
                            <div class="col-12 px-2 text-center">
                                <p class="font-weight-black custom_header">
                                    ${title}
                                </p>
                            </div>
                            <div class="col col-12 px-2">
                                ${prtHtml}
                            </div>
                        </div>
                    </div>
                </div>
            </body>
            </html>`);

            WinPrint.document.head.innerHTML += `
                <style>
                    .v-data-table__wrapper{
                        overflow: unset;
                    }
                    .record_heading_tr th{
                        text-align: center !important;
                    }
                    .custom_header{
                        background-color: #0000001f;
                        border-radius: 4px;
                        margin-bottom: 5px !important;
                    }
                    tr:hover{
                        background: unset !important;
                    }
                    .record_heading_tr:hover{
                        background: #4caf7773 !important;
                    }
                    @media print {
                        table.record_table { page-break-after:auto }
                        table.record_table tr    { page-break-inside:avoid; page-break-after:auto }
                        table.record_table td    { page-break-inside:avoid; page-break-after:auto }
                        table.record_table thead { display:table-header-group }
                        table.record_table tfoot { display:table-footer-group }
                    }
                </style>
            `;

            let rows = WinPrint.document.querySelectorAll('.record_table tr');
            rows.forEach(row => {
                row.lastChild.remove();
            })

            WinPrint.focus();
            WinPrint.document.close();
            await new Promise(resolve => setTimeout(resolve, 2000));
            WinPrint.print();
            WinPrint.close();
        }
    },
}
</script>

<style scoped>
.record_table {
    margin-bottom: 5px;
}

.record_table table,
.record_table th,
.record_table td {
    border: 1px solid #dee2e6;
    border-collapse: collapse;
    padding: 0 !important;

}

.record_table th,
.record_table td {
    padding: 2px !important;
    font-size: 11px !important;
    text-align: center;
    height: 0 !important;
}

.record_heading_tr {
    background: #4caf7773;
}

#no-background-hover::before {
    background-color: transparent !important;
}

>>>.v-btn--icon.v-size--default {
    height: unset;
    width: unset;
}

>>>.v-btn--icon.v-size--default .v-icon {
    height: unset;
    width: unset;
}
</style>